import React from 'react'
import { FooterPt } from './footerPt'
import { HeaderPt } from './header-Pt'
import { useTranslation } from 'react-i18next';



export const Safer = () => {

  const { t, i18n } = useTranslation();
 
 const changeLanguage = (lng) => {
   i18n.changeLanguage(lng);
 }

  
  return (
    <>
  
    <header>
      <HeaderPt/>
    
    </header>
    <main className="flex-shrink-2 safer">
  <div className="container">
    <div className="row m-auto boxcontent">
      <div className="col-12 text-center">
        <h2>safer</h2>
        <h3 className="color-2 text-left my-5 px-2 px-md-5">
          {t('Safer')}
        </h3>


       


       
        <div className="row d-flex align-items-center py-5">
          <div className="col-12 col-md-6">
            <img
              src="ImagensSafer/images/01.png"
              className="img-fluid"
              alt="garanta a corrida de carro do aeroporto para o seu destino final!"
            />
          </div>
          <div className="col-12 col-md-6 mt-4 mt-md-0">
            <img src="ImagensSafer/images/num-1.png" alt="Passo 1" />
            <h3 className="mt-3">
            {t('Safer2')}
            </h3>
            <p className="text-center">
            {t('Safer3')}
            </p>
          </div>
        </div>
        <div className="row d-flex align-items-center py-5">
          <div className="col-12 col-md-6">
            <img
              src="ImagensSafer/images/02.png"
              className="img-fluid"
              alt="a safer já está lá"
            />
          </div>
          <div className="col-12 col-md-6 mt-4 mt-md-0">
            <img src="ImagensSafer/images/num-2.png" alt="Passo 2" />
            <h3 className="mt-3"> {t('Safer4')}</h3>
            <p className="text-center">
            {t('Safer5')}
            </p>
          </div>
        </div>
        <div className="row d-flex align-items-center py-5">
          <div className="col-12 col-md-6">
            <img
              src="ImagensSafer/images/03.png"
              className="img-fluid"
              alt="check in rápido e fácil"
            />
          </div>
          <div className="col-12 col-md-6 mt-4 mt-md-0">
            <img src="ImagensSafer/images/num-3.png" alt="Passo 3" />
            <h3 className="mt-3">{t('Safer6')}</h3>
            <p className="text-center">
            {t('Safer7')}
            </p>
          </div>
        </div>
        <div className="row d-flex align-items-center py-5">
          <div className="col-12 col-md-6">
            <img
              src="ImagensSafer/images/04.png"
              className="img-fluid"
              alt="boa viagem!"
            />
          </div>
          <div className="col-12 col-md-6 mt-4 mt-md-0">
            <img src="ImagensSafer/images/num-4.png" alt="Passo 4" />
            <h3 className="mt-3">{t('Safer8')}</h3>
            <p className="text-center">
            {t('Safer9')}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

    <footer>
      <FooterPt/>
    </footer>
  </>

  )
}
