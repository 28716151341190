import React from 'react'
import { FooterPt } from './footerPt'
import { HeaderPt } from './header-Pt'
import {useTranslation} from 'react-i18next'


export const Viagens = () => {

  const { t, i18n } = useTranslation();
 
 const changeLanguage = (lng) => {
   i18n.changeLanguage(lng);

 }

  return (
    <>
  
  <header>
    <HeaderPt/>
    
    </header>
    <main className="flex-shrink-0 safer-viajantes">
  <div className="container">
    <div className="row m-auto boxcontent">
      <div className="col-12 text-left">
        <h2 className="text-center">{t('Viajantes')}</h2>
        <h3 className="color-2 text-left mt-5">
        {t('Viajantes2')}
        </h3>
        <h3 className="mt-5 pb-3" style={{ fontSize: 24 }}>
        {t('Viajantes3')}
        </h3>
        <p>
        {t('Viajantes4')}
        </p>
        <p>
        {t('Viajantes5')}
        </p>
        <h3 className="color-2 mt-5">{t('Viajantes6')}</h3>
        <div className="buttons-app d-flex flex-column justify-content-center text-center float-left mt-5">
          <p>
            <strong>{t('Viajantes7')}</strong>
          </p>
          <a
            role="button"
            title="IOS icon download"
            href="https://apps.apple.com/us/app/safer/id1594436694"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="ImagensSafer/images/btn-appleen.png" />
          </a>
          <a
            role="button"
            title="Android icon download"
            href="https://play.google.com/store/apps/details?id=com.saferapp.passenger"
            target="_blank"
            rel="noopener noreferrer"
            className="mt-3"
          >
            <img src="ImagensSafer/images/btn-googleen.png" />
          </a>
        </div>
      </div>
    </div>
  </div>
</main>

    <footer >
    <FooterPt/>
    </footer>
  </>
  )
}
