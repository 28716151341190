import React from 'react'
import { FooterPt } from './footerPt'
import { HeaderPt } from './header-Pt'
import {useTranslation} from 'react-i18next'



export const Motoristas = () => {
  const { t, i18n } = useTranslation();
 
 const changeLanguage = (lng) => {
   i18n.changeLanguage(lng);

 }



  return (
    <>
  
  <header>
    <HeaderPt/>
    </header>
    <main className="flex-shrink-0 safer-motoristas">
  <div className="container">
    <div className="row m-auto boxcontent">
      <div className="col-12 text-left">
        <h2 className="text-center">{t('Motoristas')}</h2>
        <p className="mt-5">
        {t('Motoristas2')}
        </p>
        <p>
        {t('Motoristas3')}
        </p>
        <div className="row mt-5 d-flex justify-content-between">
          <div className="col-12 col-md-4 pr-md-0">
            <img src="ImagensSafer/images/plane.png" style={{ marginLeft: 40 }} />
            <h3 className="py-3 color-4"> {t('Motoristas4')}</h3>
            <ul className="list-unstyled">
              <li style={{ fontWeight: 900 }}>EUA</li>
              <li> {t('Motoristas5')}</li>
              <li style={{ fontWeight: 900, marginTop: 10 }}>{t('Motoristas17')}</li>
              <li> {t('Motoristas6')}</li>
              <li> {t('Motoristas7')}</li>
              <li> {t('Motoristas8')}</li>
              <li> {t('Motoristas9')}</li>
              <li> {t('Motoristas10')}</li>
              <li> {t('Motoristas11')}</li>
            </ul>
          </div>
          <div className="col-12 col-md-4">
            <img src="ImagensSafer/images/bus.png" style={{ marginLeft: 40 }} />
            <h3 className="py-3 color-3"> {t('Motoristas12')}</h3>
            <ul className="list-unstyled">
              <li> {t('Motoristas13')}</li>
              <li> {t('Motoristas14')}</li>
              <li> {t('Motoristas15')}</li>
              <li> {t('Motoristas16')}</li>
            </ul>
          </div>
          <div className="col-12 col-md-3">
            <img src="ImagensSafer/images/bag.png" style={{ marginLeft: 40 }} />
            <h3 className="py-3 color-1">{t('Motoristas18')}</h3>
            <ul className="list-unstyled">
              <li style={{ fontWeight: 900 }}>Rio de Janeiro</li>
              <li>Barra Shopping</li>
              <li>Rio Design Barra</li>
              <li>Rio Sul</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

    <footer >
      <FooterPt/>
    </footer>
  </>
  )
}
