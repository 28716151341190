import React from 'react'
import { FooterPt } from './footerPt'
import { HeaderPt } from './header-Pt'
import {useTranslation} from 'react-i18next'
import { HeaderHome } from './Header-Home'



export const HomeEn = () => {
  const { t, i18n } = useTranslation();
 
 const changeLanguage = (lng) => {
   i18n.changeLanguage(lng);

 }
  
  return (
    <>
    
  <header className="home">
    
  <HeaderHome/>
    <div className="slider container">
      <h2>
      {t('Home')}
      </h2>
      <p>
      {t('Home2')}
      </p>
      <div className="buttons-app d-flex flex-column justify-content-center text-center mt-4">
        <p>{t('Home3')}</p>
        <a
          role="button"
          title="IOS icon download"
          href="https://apps.apple.com/us/app/safer/id1594436694"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="ImagensSafer/images/btn-appleen.png" />
        </a>
        <a
          role="button"
          title="Android icon download"
          className="mt-3"
          href="https://play.google.com/store/apps/details?id=com.saferapp.passenger"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="ImagensSafer/images/btn-googleen.png" />
        </a>
      </div>
    </div>
  </header>

  <main className="flex-shrink-0 home2">
      <div className="container">
        <div className="row m-auto boxcontent">
          <div className="col-12 text-center">
            <h2>{t('Home4')}</h2>
            <div className="row d-flex align-items-center mt-5">
              <div className="col-12 col-md-6">
                <img
                  src="ImagensSafer/images/01.png"
                  className="img-fluid"
                  alt="garanta a corrida de carro do aeroporto para o seu destino final!"
                />
              </div>
              <div className="col-12 col-md-6 mt-4 mt-md-0">
                <img src="ImagensSafer/images/num-1.png" alt="Passo 1" />
                <h3 className="mt-3">
                {t('Home5')}
                </h3>
                <p className="text-center">
                {t('Home6')}
                </p>
              </div>
            </div>
            <div className="row d-flex align-items-center mt-5">
              <div className="col-12 col-md-6">
                <img
                  src="ImagensSafer/images/02.png"
                  className="img-fluid"
                  alt="a safer já está lá"
                />
              </div>
              <div className="col-12 col-md-6 mt-4 mt-md-0">
                <img src="ImagensSafer/images/num-2.png" alt="Passo 2" />
                <h3 className="mt-3">{t('Home7')}</h3>
                <p className="text-center">
                {t('Home8')}
                </p>
              </div>
            </div>
            <div className="row d-flex align-items-center mt-5">
              <div className="col-12 col-md-6">
                <img
                  src="ImagensSafer/images/03.png"
                  className="img-fluid"
                  alt="check in rápido e fácil"
                />
              </div>
              <div className="col-12 col-md-6 mt-4 mt-md-0">
                <img src="ImagensSafer/images/num-3.png" alt="Passo 3" />
                <h3 className="mt-3">{t('Home9')}</h3>
                <p className="text-center">
                {t('Home10')}
                </p>
              </div>
            </div>
            <div className="row d-flex align-items-center mt-5">
              <div className="col-12 col-md-6">
                <img
                  src="ImagensSafer/images/04.png"
                  className="img-fluid"
                  alt="boa viagem!"
                />
              </div>
              <div className="col-12 col-md-6 mt-4 mt-md-0">
                <img src="ImagensSafer/images/num-4.png" alt="Passo 4" />
                <h3 className="mt-3">{t('Home11')}</h3>
                <p className="text-center">
                {t('Home12')}
                </p>
              </div>
            </div>
            <div className="row" style={{ marginTop: 90 }}>
              <div className="col-12">
              
                <h2 className="mt-5">{t('Home13')}</h2>
              </div>
              
            </div>
            
            <div className="row d-flex justify-content-center d-block d-md-none mt-5">
              <div className="col-9 mb-3">
                <img src="ImagensSafer/images/bl-01en.png" className="img-fluid" />
              </div>
              <div className="col-9 mb-3">
                <img src="ImagensSafer/images/bl-02en.png" className="img-fluid" />
              </div>
              <div className="col-9 mb-3">
                <img src="ImagensSafer/images/bl-03en.png" className="img-fluid" />
              </div>
              <div className="col-9 mb-0">
                <img src="ImagensSafer/images/bl-04en.png" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  
  <footer >
   <FooterPt/>
  </footer>
  </>


  )
}
