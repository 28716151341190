import React from 'react'
import {useTranslation} from 'react-i18next'
import {BsInstagram} from 'react-icons/bs'
import {TfiLinkedin} from 'react-icons/tfi'


export const HeaderPt = () => {
  const { t, i18n } = useTranslation();
 
 const changeLanguage = (lng) => {
   i18n.changeLanguage(lng);

 }
  return (
    <>
     
   <header>
  <nav className="navbar navbar-dark ">
    <div className="container d-flex align-items-end">
      <a className="navbar-brand" href="/">
        <img src="ImagensSafer/images/logo.png" alt="Safer" />
      </a>
      <div>
        <div>
          <div className="lang float-right mt-1 mr-2 mr-md-3">
            
        
          <button onClick={() => changeLanguage('pt-BR')}>
          {t('PT')}
          </button>
          <button onClick={() => changeLanguage('en-US')} >
           {t('EN')}
          </button>
          <button onClick={() => changeLanguage('es-ES')}>
           {t('ES')}
           </button>

            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
              aria-label="Toggle navigation"
              style={{ margin: "1em" }}
            >
              <span className="navbar-toggler-icon" />
            </button>
          </div>
        </div>
        
        <div
          id="social-navbar"
          className="d-flex justify-content-end align-items-center "
        >
          <a
            href="https://br.linkedin.com/company/safertaxi-limited"
            target="_blank"
            className="circle d-flex justify-content-center align-items-center mr-4"
            style={{ margin: 10 }}
          >
            <i className="fab fa-linkedin-in" />
            <TfiLinkedin/>
          </a>
          <a
            href="https://instagram.com/saferbrasil"
            target="_blank"
            className="circle d-flex justify-content-center align-items-center"
            
          >
            <i className="fab fa-instagram" />
            <BsInstagram/>
          </a>
          <div className="offcanvas offcanvas-end" id="offcanvasNavbar">
            <div className="offcanvas-header">
              <ul className="menu">
                
                <li className="menu-item ">
                  <a class="nav-link active" aria-current="page" href="safer">{t('Header')}</a>
                </li>
                <li className="menu-item ">
                  <a class="nav-link active" aria-current="page" href="safer-empresas">
                  {t('Header2')}</a>
                </li>
                <li className="menu-item ">
                 <a class="nav-link active" aria-current="page" href="safer-viajantes">{t('Header3')}</a>
                </li>
                <li className="menu-item ">
                  <a class="nav-link active" aria-current="page" href="safer-motoristas">{t('Header4')}</a>
                </li>
                <li className="menu-item ">
                  <a class="nav-link active" aria-current="page" href="Safer-Bank">{t('Header5')}</a>
                </li>
                <li className="menu-item ">
                  <a class="nav-link active" aria-current="page" href="contato">{t('Header6')}</a>
                </li>
              </ul>
            </div>
            <div
              id="social-navbar"
              className="d-flex justify-content-end align-items-center "
            >
              <a
            href="https://br.linkedin.com/company/safertaxi-limited"
            target="_blank"
            className="circle d-flex justify-content-center align-items-center mr-4"
            style={{ margin: 10 }}
          >
            <i className="fab fa-linkedin-in" />
            <TfiLinkedin/>
          </a>
          <a
            href="https://instagram.com/saferbrasil"
            target="_blank"
            className="circle d-flex justify-content-center align-items-center"
            style={{ marginRight: 30 }}
          >
            <i className="fab fa-instagram" />
            <BsInstagram/>
          </a>

            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>

 
  </header>
</>
  )
}
