import React from 'react'
import {useTranslation} from 'react-i18next'
import {BsInstagram} from 'react-icons/bs'
import {TfiLinkedin} from 'react-icons/tfi'

export const FooterPt = () => {
  const { t, i18n } = useTranslation();
 
 const changeLanguage = (lng) => {
   i18n.changeLanguage(lng);

 }
  return (
    <footer className="footer mt-auto py-3">
  <div className="container">
    <ul className="d-flex flex-column flex-md-row justify-content-md-between list-unstyled pt-5 py-md-5">
      <li className="item mb-4 mb-md-0 active">
        <a href="safer">{t('Footer')}</a>
      </li>

      <li className="item mb-4 mb-md-0">
        <a href="safer-empresas">
        {t('Footer2')}<br className="d-none d-md-block" />
          
        </a>
      </li>
      
      <li className="item mb-4 mb-md-0">
        <a href="safer-viajantes">
        {t('Footer3')}<br className="d-none d-md-block" />
          
        </a>
      </li>
      <li className="item mb-4 mb-md-0">
        <a href="safer-motoristas">
        {t('Footer4')}<br className="d-none d-md-block" />
          
        </a>
      </li>
      <li className="item mb-4 mb-md-0">
        <a href="safer-bank">
        {t('Footer5')}<br className="d-none d-md-block" />
          
        </a>
      </li>
      <li className="item mb-0 mb-md-0">
        <a href="contato">{t('Footer6')}</a>
      </li>
    </ul>
    <div className="row pt-5 pb-4 d-flex align-items-end">
      <div className="col-12 col-md-6 d-flex justify-content-start align-items-end">
        <a href="/" className="logo-footer">
          <img src="ImagensSafer/images/logo.png" alt="Safer" />
        </a>
        
        <h3 className="m-0 ml-4 p-0 subtitle-footer">one Journey</h3>
      </div>
      <div className="col-12 col-md-6 mt-5 mt-md-0">
        <div className="row">
          <div className="col-12 col-md-8 buttons-app">
            <div className="float-right" style={{ width: 360 }}>
              <p className="w-100 text-center">download the app</p>
              <div className="d-flex justify-content-between">
                <a
                  role="button"
                  title="IOS icon download"
                  href="https://apps.apple.com/us/app/safer/id1594436694"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mr-4"
                >
                  <img src="ImagensSafer/images/btn-appleen.png" />
                </a>
                <a
                  role="button"
                  title="Android icon download"
                  href="https://play.google.com/store/apps/details?id=com.saferapp.passenger"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="ImagensSafer/images/btn-googleen.png" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 mt-5 mt-md-0 d-flex justify-content-center justify-content-md-end align-items-end">
            <a
              href="https://br.linkedin.com/company/safertaxi-limited"
              target="_blank"
              className="circle d-flex justify-content-center align-items-center mr-3"
              style={{ margin: 10 }}
              
            >
              <i className="fab fa-linkedin-in" />
              <TfiLinkedin/>
            </a>
            <a
              href="https://instagram.com/saferbrasil"
              target="_blank"
              className="circle d-flex justify-content-center align-items-center"
              style={{ margin: 10 }}
            >
              <i className="fab fa-instagram" />
              <BsInstagram/>
              
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="row px-0 pb-5 policy">
      <div className="col-12 px-0">
        <a
          href="termos"
          className="d-flex justify-content-center justify-content-md-end float-md-right"
        >
          Policy and Terms
        </a>
      </div>
    </div>
  </div>
</footer>
  )
}
