import React from 'react'
import { FooterPt } from './footerPt'
import { HeaderPt } from './header-Pt'
import {useTranslation} from 'react-i18next'

export const Contato = () => {
  const { t, i18n } = useTranslation();
 
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
 
  }
  return (
    <>
  
    <header>
    <HeaderPt/>
    </header>{" "}
    <main className="flex-shrink-0 contato">
  <div className="container">
    <div className="row m-auto boxcontent">
      <div className="col-12 text-left">
        <h2 className="text-center">{t('Contato')}</h2>
        <form
          
        >
          <div className="d-flex justify-content-start mt-5 mb-2">
            <div className="color-2">
              <input
                type="radio"
                name="tipo"
                id="motoristas"
                defaultValue="motoristas"
                defaultChecked=""
              />{" "}
              <label htmlFor="motoristas">{t('Contato2')}</label>
              {" "}
            </div>
            
            <div className="color-1 ml-3">
              <input
                type="radio"
                name="tipo"
                id="viajantes"
                defaultValue="viajantes"
              />{" "}
              <label htmlFor="viajantes">{t('Contato3')}</label>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <input
                type="text"
                placeholder={t('Contato4')}
                name="nome"
                className="form-control"
                required=""
              />
            </div>
            <div className="col-12 mt-3">
              <input
                type="email"
                placeholder="email"
                name="email"
                className="form-control"
                required=""
              />
            </div>
            <div className="col-6 mt-3">
              <input
                type="text"
                placeholder={t('Contato5')}
                name="CPF"
                className="form-control"
                data-toggle="input-mask"
                data-mask-format="000.000.000-00"
                required=""
              />
            </div>
            <div className="col-6 mt-3">
              <input
                type="text"
                placeholder="whatsapp"
                name="whatsapp"
                className="form-control"
                data-toggle="input-mask"
                data-mask-format="(00) 00000-0000"
                required=""
              />
            </div>
            <div className="col-12 mt-3">
              <input
                type="text"
                placeholder={t('Contato6')}
                name="assunto"
                className="form-control"
                required=""
              />
            </div>
            <div className="col-12 mt-3">
              <textarea
                placeholder={t('Contato7')}
                name="mensagem"
                className="form-control"
                rows={6}
                required=""
                defaultValue={""}
              />
            </div>
            <div className="col-2 mt-3">
              <button
                type="submit"
                className="btn btn-rounded btn-sm bg-color-2 px-3 btn-contato"
                style={{ fontSize: 17 }}
              >
                {t('Contato8')}
              </button>
            </div>
            <div className="col-10 mt-3">
              <p
                style={{ fontWeight: "bold", color: "#ed0677" }}
                className="retorno"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</main>


    <footer >
      <FooterPt/>

    </footer>
  </>
  )
}
