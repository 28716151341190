import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Home } from '../Pages/Home'
import { Safer } from '../Pages/Safer'
import { Empresa } from '../Pages/Safer-empresa'
import { Viagens } from '../Pages/Safer-viagens'
import { Motoristas } from '../Pages/Safer-motoristas'
import { Bank } from '../Pages/Safer-Bank(Verificar)'
import { Contato } from '../Pages/Contato'
import { Termos } from '../Pages/Termos'
import { Saferlangen } from '../Pages/Safer-en'
import {EmpresaLangEn} from '../Pages/SaferEmpresa-En'
import {ViagensLangEn} from '../Pages/Safer-viagens-En'
import { MotoristasLangEn } from '../Pages/Safer-motoristas-En'
import { BankLangEn } from '../Pages/Safer-Bank-En'
import { Contatolangen } from '../Pages/Contato-En'

import { SaferLangEs } from '../Pages/Safer-es'
import { EmpresaLangEs } from '../Pages/Safer-empresa-es'
import { ViajantesLangEs } from '../Pages/Safer-Viajantes-es'
import { MotoristasLangEs } from '../Pages/Safer-motoristas-es'
import { BankLangEs } from '../Pages/Safer-bank-es'
import { HomeLangEn } from '../Pages/Home-en'
import { FooterPt} from '../Pages/footerPt'
import { FooterEn } from '../Pages/footer-en'
import { FooterEs } from '../Pages/footer-Es'
import { HeaderPt } from '../Pages/header-Pt'
import { HeaderEn } from '../Pages/header-En'
import { HeaderHome } from '../Pages/Header-Home'
import { HomeEn } from '../Pages/Home-en'
import { HomeES } from '../Pages/Home-es'
import { Privacy } from '../Pages/Privacy'


export const Router = () => {
  return (

    <BrowserRouter>
    <Routes>
    <Route path="/" element={<Home/>} />
    <Route path="/Safer" element={<Safer/>} />  
    <Route path="/Safer-empresas" element={<Empresa/>} />
    <Route path="/Safer-viajantes" element={<Viagens/>} />
    <Route path="/Safer-Motoristas" element={<Motoristas/>} />
    <Route path="/Safer-Bank" element={<Bank/>} />
    <Route path="/Contato" element={<Contato/>} />
    <Route path="/Termos" element={<Termos/>} />
    <Route path="/FooterPt" element={<FooterPt/>} />
    <Route path="/HeaderPt" element={<HeaderPt/>} />
   
    <Route path="/HeaderHome" element={<HeaderHome/>} />
    <Route path="/HomeEN" element={<HomeEn/>} />
    <Route path="/HomeES" element={<HomeES/>} />
    <Route path="/Privacy" element={<Privacy/>} />

    </Routes>
    </BrowserRouter>
  )
}
