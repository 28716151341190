import React from 'react'
import { FooterPt } from './footerPt'
import { HeaderPt } from './header-Pt'
import {useTranslation} from 'react-i18next'


export const Termos = () => {
  const { t, i18n } = useTranslation();
 
 const changeLanguage = (lng) => {
   i18n.changeLanguage(lng);

 }

  return (
    <>
  
  <header>
  <HeaderPt/>
    </header>{" "}
    <main className="flex-shrink-0 termos">
  <div className="container">
    <div className="row m-auto boxcontent">
      <div className="col-12 text-left">
        <h2 className="text-center">{t('Termos')}</h2>
        <h3 className="color-2 text-left mt-5">{t('Termos2')}</h3>
        <h3 className="mt-5 pb-3" style={{ fontSize: 24 }}>
        {t('Termos3')}
        </h3>
        <p>
        {t('Termos4')}
        </p>
        <p>
        {t('Termos5')}
        </p>
        <p>
        {t('Termos6')}
        </p>
        <h3 className="mt-5 pb-3" style={{ fontSize: 24 }}>
        {t('Termos7')}
        </h3>
        <p>
        {t('Termos8')}
        </p>
        <p>
        {t('Termos9')}
        </p>
        <h3 className="mt-5 pb-3" style={{ fontSize: 24 }}>
        {t('Termos10')}
        </h3>
        <p>
        {t('Termos11')}
        </p>
        <h3 className="mt-5 pb-3" style={{ fontSize: 24 }}>
        {t('Termos12')}
        </h3>
        <p>
        {t('Termos13')}
        </p>
        <h3 className="mt-5 pb-3" style={{ fontSize: 24 }}>
        {t('Termos14')}
        </h3>
        <p>
        {t('Termos15')}
        </p>
        <h3 className="mt-5 pb-3" style={{ fontSize: 24 }}>
        {t('Termos16')}
        </h3>
        <p>
        {t('Termos17')}
        </p>
        <h3 className="mt-5 pb-3" style={{ fontSize: 24 }}>
        {t('Termos18')}
        </h3>
        <p>
        {t('Termos19')}
        </p>
        <h3 className="mt-5 pb-3" style={{ fontSize: 24 }}>
        {t('Termos20')}
        </h3>
        <p>
        {t('Termos21')}
        </p>
        <p>
        {t('Termos22')}
        </p>
        <h3 className="mt-5 pb-3" style={{ fontSize: 24 }}>
        {t('Termos23')}
        </h3>
        <p>
        {t('Termos24')}
        </p>
        <p>
        {t('Termos25')}
        </p>
        <p></p>
        <ol style={{ listStyleType: "lower-roman" }}>
          <li>
          {t('Termos26')}
          </li>
          <li>{t('Termos27')}</li>
          <li>{t('Termos28')}</li>
          <li>{t('Termos29')}</li>
          <li>{t('Termos30')}</li>
          <li>{t('Termos31')}</li>
          <li>
          {t('Termos32')}
          </li>
          <li>{t('Termos33')} </li>
          <li>{t('Termos34')}</li>
          <li>{t('Termos35')}</li>
          <li>
          {t('Termos36')}
          </li>
          <li>
          {t('Termos37')}
          </li>{" "}
        </ol>
        <p />
        <p>
        {t('Termos38')}
        </p>
        <p>
          • {t('Termos39')}
        </p>
        <p>
          • {t('Termos40')}
        </p>
        <p>
          • {t('Termos41')}
        </p>
        <p>
          • {t('Termos42')}{" "}
        </p>
        <p>
          • {t('Termos43')}
        </p>
        <p>
          • {t('Termos44')}
        </p>
        <p>
          • {t('Termos45')}{" "}
        </p>
        <p>
          • {t('Termos46')}
        </p>
        <p>
          • {t('Termos47')}
        </p>
        <p>
          • {t('Termos48')}{" "}
        </p>
        <p>
        {t('Termos49')}
        </p>
        <p>
        {t('Termos50')}
        </p>
        <p>
        {t('Termos51')}
        </p>
        <h3 className="mt-5 pb-3" style={{ fontSize: 24 }}>
        {t('Termos52')}
        </h3>
        <p>
        {t('Termos53')}
        </p>
        <p>
        {t('Termos54')}{" "}
        </p>
        <p>
        {t('Termos55')}
        </p>
        <p>
        {t('Termos56')}
        </p>
        <h3 className="mt-5 pb-3" style={{ fontSize: 24 }}>
        {t('Termos57')}
        </h3>
        <p>
        {t('Termos58')}
        </p>
        <p>
        {t('Termos59')}
        </p>
        <p>
        {t('Termos60')}{" "}
        </p>
        <p>
        {t('Termos61')}
        </p>
        <h3 className="mt-5 pb-3" style={{ fontSize: 24 }}>
        {t('Termos62')}
        </h3>
        <p>
        {t('Termos63')}{" "}
        </p>
        <p>
        {t('Termos64')}
        </p>
        <p>
        {t('Termos65')}
        </p>
        <h3 className="mt-5 pb-3" style={{ fontSize: 24 }}>
        {t('Termos66')}
        </h3>
        <p>
        {t('Termos67')}
        </p>
        <p>
        {t('Termos68')}
        </p>
        <p>
        {t('Termos69')}
        </p>
        <p>
        {t('Termos70')}
        </p>
        <p>
        {t('Termos71')}
        </p>
        <h3 className="mt-5 pb-3" style={{ fontSize: 24 }}>
        {t('Termos72')}
        </h3>
        <p>
        {t('Termos73')}
        </p>
        <p>
          <strong>
          {t('Termos74')}
            <br />
            {t('TermosPhone')}
          </strong>
        </p>
        <h3 className="mt-5 pb-3" style={{ fontSize: 24 }}>
        {t('Termos75')}
        </h3>
        <p>
        {t('Termos76')}
        </p>
        <p>
        {t('Termos77')}
        </p>
      </div>
    </div>
  </div>
</main>

    
    <footer >
      <FooterPt/>
    </footer>
  </>

  )
}
