import React from 'react'
import { FooterPt } from './footerPt'
import { HeaderPt } from './header-Pt'
import {useTranslation} from 'react-i18next'

export const Bank = () => {
  const { t, i18n } = useTranslation();
 
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
 
  }

  return (
    <>
  
  <header>
 
  <HeaderPt/>

</header>
    <main className="flex-shrink-0 safer-bank">
    <div className="container">
      <div className="row m-auto boxcontent">
        <div className="col-12 text-left">
          <h2 className="text-center color-4">Safer Bank</h2>
          <h3 className="color-2 text-left mt-5">
          {t('Bank')}
          </h3>
          <div className="mt-5 text-center">
            <img src="ImagensSafer/images/safer-bank-logo.png" alt="Safer Bank" />
          </div>
          <h3 className="mt-5 pb-3 color-4" style={{ fontSize: 24 }}>
          {t('Bank2')}
          </h3>
          <p>
          {t('Bank3')}
          </p>
          <h3 className="py-3 color-4">e-wallet</h3>
          <p>
          {t('Bank4')}
          </p>
          <p>
          {t('Bank5')}
          </p>
          
          <a
            href="https://webbanking.saferbank.secure.srv.br/"
            target="_blank"
            className="btn btn-rounded btn-sm bg-color-2 px-5 mt-3"
            style={{ fontSize: 17 }}
          >
            {t('Bank6')}
          </a>
        </div>
      </div>
    </div>
  </main>
    <footer >
      <FooterPt/>
    </footer>
  </>
  
  )
}
