import React from 'react'
import { FooterPt } from './footerPt'
import { HeaderPt } from './header-Pt'
import {useTranslation} from 'react-i18next'


export const Empresa = () => {
  const { t, i18n } = useTranslation();
 
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
 
  }

  return (
    <>
  
  <header>
    <HeaderPt/>
    </header>{" "}
    <main className="flex-shrink-0 safer-empresas">
  <div className="container">
    <div className="row m-auto boxcontent">
      <div className="col-12 text-left">
        <h2 className="text-center">{t('Empresas')}</h2>
        <h3 className="color-2 text-left mt-5">
        {t('Empresas2')}
        </h3>
        <h3 className="mt-5 pb-3" style={{ fontSize: 24 }}>
        {t('Empresas3')}
        </h3>
        <p>
        {t('Empresas4')}
        </p>
        <h3 className="py-3">{t('Empresas5')}</h3>
        <p>
        {t('Empresas6')}
        </p>
        <p>
        {t('Empresas7')}
        </p>
        <p>
        {t('Empresas8')}
        </p>
        <div className="text-center float-left">
          <h3 className="color-2 mt-5">{t('Empresas10')}</h3>
          <a
            href="mailto:faleconosco@safertaxi.com"
            className="btn btn-rounded btn-sm bg-color-2 px-5"
            style={{ fontSize: 17 }}
          >
            {t('Empresas9')}
            

          </a>
        </div>
        <div className="clearfix" />
      </div>
    </div>
  </div>
</main>

    
    <footer >
    <FooterPt/>
    </footer>
  </>

  )
}
